import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import ReactPlayer from 'react-player'
import { motion, useViewportScroll, transform } from 'framer-motion'
import { usePortals } from "react-portal-hook";

import { Layout, PageHero, PageSection, Mark, AnimatedText, VideoModal, VideoCover, MarkLink, SubscribeBanner, Block } from '../components'

import { container, padding, colours, type, bgImage, bgIcon, pagePaddingTop, wrapper } from '../styles/global'
import { parseACF } from '../utils'
import { useScrollAnim } from '../utils/anim'

import plusIcon from '../assets/icons/accordion-show.svg'
import { media } from '../styles/utils'

const Live = (props) => {
    const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', true)
    const portalManager = usePortals()

    const showVideo = (video) => {
        portalManager.open(modal => (
			<VideoModal
                showSubscribe={true}
                video={video}
				{...modal}
			/>
        ))
	}
    
    const renderSeriesIntro = (introText, bodyText) => {
        if (!introText || !bodyText) return


        return (
            <Block 
                className={'series-intro'}
                layout={'text_image'}
                intro_text={introText}
                body_text={bodyText}
                style={{
                    marginBottom: 35
                }}
            />
        )
    }

    const renderIntroSection = (title, introText, bodyText) => {
        if (!title || !introText || !bodyText) return

		const section = {
			title: title,
			items: [
				{
                    intro_text: introText,
					body_text: bodyText,
				}
			]
		}

        return (
            <PageSection
                {...section}
            />
        )
    }

    const renderItemContent = item => {
        return (
            <ItemContent>
                <Heading>
                    <Mark size={'medium'} />
                    {item.heading}
                </Heading>

                {renderItemSidebar(item, 'phone')}

				<Text	
					as={AnimatedText}
					text={item.text}
				/>
            </ItemContent>
        )
    }

    const getVideoURL = (iframe) => {
		if (iframe) {
			return iframe.match(/src="(.+?)"/)[1]
		}
	}

    const renderItemSidebar = (item, breakpoint) => {
        return (
            <ItemSidebar
                breakpoint={breakpoint}
            >
                <VideoWrapper
                    onClick={() => showVideo(item.video)}
                >
                    <VideoCover
                        data={item}
                    />
                </VideoWrapper>
            </ItemSidebar>
        )
    }

    const renderSectionItems = section => {
        return section.items.map((item, i) => {
            return (
                <Item key={i}>
                    {renderItemContent(item)}
                    {renderItemSidebar(item, 'desktop')}
                </Item>
            )
        })
    }

    const renderVimeoLink = (section) => {
        if (!section.use_external_link) return;

        return (
            <MarkLink
                as={VimeoLink}
                href={section.external_link}
                target={'_blank'}
            >
                {section.external_link_text}
                
                <Icon 
                    image={plusIcon}
                />
            </MarkLink>
        )
    }

    const renderMoreEpisodes = (section) => {
        return (
            <PageSection
                title={undefined}
                renderContent={() => renderVimeoLink(section)}
            />
        )
    }

	const renderSections = () => {
        if (!data.sections) return;

		const items = data.sections.map((section, i) => {
			if (!section.display) return;

			return (
                <>
                    <PageSection 
                        key={i}
                        title={section?.title}
                        renderContent={() => {
                            return (
                                <>
                                   {section.intro_text && (
                                        renderSeriesIntro(section.heading, section.intro_text)
                                    )}

                                    {renderSectionItems(section)}
                                </>
                            )
                        }}
                    />

                    {renderMoreEpisodes(section)}
                </>
			)
        })

		return items
	}
    
	return (
		<Layout
            meta={data.seo}
        >
			<Wrapper>
                <PageHero
                    data={{
                        ...data,
                        logo: true,
                        title: '/Live'
                    }}
                />

                <Container>
                    {renderIntroSection(data.intro_heading, data.intro_text, data.intro_body_text)}
                    {renderSections()}
                </Container>

                <SubscribeBanner
                    inline={true}
                />
			</Wrapper>
		</Layout>	
	)
}


// Shared

const Heading = styled(motion.h2)``
const Subheading = styled.h3``
const Text = styled.div``
const Info = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled(motion.div)`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

const Icon = styled.div`
    background-image: url(${props => props.image});  
    ${bgIcon};
`

// Layout

const Wrapper = styled.div`
    ${wrapper}
`

const Container = styled.div`
	${container}
	${padding}
    ${pagePaddingTop}

	display: flex;
	flex-direction: column;

    /* Series intro override */

    .series-intro {
        ${media.phone`
            margin-bottom: 80px;
        `}
    }
`

// More Episodes (Vimeo Links)

const VimeoLink = styled.a`
    display: flex;
    justify-content: center;

    ${Icon} {
        width: 12px;
        height: 12px;
        margin-left: 8px;
    }
`

// Section Items

const Item = styled.div`
	display: flex;
    justify-content: space-between;
    margin-bottom: 120px;

    ${media.phone`
        flex-direction: column;
        margin-bottom: 62px;

        &:last-child {
            margin-bottom: 62px !important;
        }
    `}
`

// Item Content

const ItemContent = styled.div`
	display: flex;
	flex-direction: column;
    width: 50%;
    max-width: 587px;
	padding-right: 118px;

	> *:not(:last-child) {
		margin-bottom: 26px;
	}

	p:last-child {
		margin-bottom: 0;
	}

    ${Heading} {
        display: flex;
        align-items: center;
        ${type.heading2}

        .mark {
            width: 117px;
            background: ${colours.lemon};

            ${media.phone`
                width: 75px;
            `}
        }
    }

    ${Text} {
        max-width: 765px;
        ${type.heading3}

        p {
            margin-bottom: 0;
        }
    }

    ${media.phone`
        width: 100%;
        padding-right: 0;
        max-width: none;
    `}
`

// Item Sidebar (Video)

const VideoWrapper = styled.div``
const Video = styled(ReactPlayer)``

const ItemSidebar = styled.div`
    width: 50%;
	max-width: 558px;

    ${VideoWrapper} {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        cursor: pointer;
    }

    .video-cover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100% !important;
        height: 100% !important;

        .react-player__preview {
            pointer-events: none;
        }

        > div {
            width: 100%!important;
            height: 100%!important;
        }
    }

    /* Breakpoint display */

	${props => {
		if (props.breakpoint == 'desktop') return css`
			${media.phone`
				display: none;
			`}
		`

		if (props.breakpoint == 'phone') return css`
			display: none;

			${media.phone`
				display: flex;
			`}
		`
	}}

    ${media.phone`
        width: 100%;
    `}
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "live" } }) {
            nodes {
                title
                slug
                acf_json
            }
        }
    }
`

export default Live
